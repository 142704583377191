<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,rightIcon:true}"></v-header>
        </div>
        <div class="none">
            <img src="../../assets/images/none.png" alt="">
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .none{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 36px;
                height: 36px;
            }
        }
    }
</style>